export interface DiffSelectionPayload {
  diffId: string
  start?: string
  end?: string
}

export class DiffSelectionEvent extends Event {
  declare payload: DiffSelectionPayload | undefined
  constructor(payload: DiffSelectionPayload | undefined) {
    super('diff-line-selection', {
      bubbles: false,
      cancelable: true,
    })
    this.payload = payload
  }
}

declare global {
  interface WindowEventMap {
    'diff-line-selection': DiffSelectionEvent
  }
}

export function publishDiffSelection(payload: DiffSelectionPayload | undefined): void {
  window.dispatchEvent(new DiffSelectionEvent(payload))
}

export function subscribeDiffSelection(listener: (e: DiffSelectionEvent) => void): () => void {
  window.addEventListener('diff-line-selection', listener)
  return () => window.removeEventListener('diff-line-selection', listener)
}
